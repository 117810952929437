// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
// Set vars
var newUrl = "";

// DELETE SOMETHING WITH AN ID
export async function deleteId(id, what, handleUpdateFromComp) {

  if (what === "list") { var apiPath = "/api/lists"; }
  if (what === "item") { var apiPath = "/api/list/items"; }

  return new Promise((resolve, reject) => {
    newUrl = url + apiPath + "?id=" + String(id);

    fetch(newUrl, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", },
    })
    .then((response) => {
      if (response.ok) {
        // give it time to update before we refresh all data
        setTimeout(function(){ 
        handleUpdateFromComp(); 
        
        }, 1000);
 

      } else {
        // Request failed
        throw new Error("Request failed");
      }
    })
    .catch((error) => {
      // Request error
      console.error("Error during API request:", error);
    });
  });
  
}

// DELETE A FRIEND
export async function deleteFriend(list_id, human_id, handleUpdateFromComp) {

  var apiPath = "/api/friend";

  return new Promise((resolve, reject) => {
    const newUrl = url + apiPath + "?list_id=" + String(list_id) + "&human_id=" + String(human_id);

    fetch(newUrl, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", },
    })
    .then((response) => {
      if (response.ok) {
        // give it time to update before we refresh all data
        setTimeout(function(){ 
        handleUpdateFromComp(); 
        
        }, 1000);
 

      } else {
        // Request failed
        throw new Error("Request failed");
      }
    })
    .catch((error) => {
      // Request error
      console.error("Error during API request:", error);
    });
  });
  
}

