import React, { useState } from "react";
import { Link, Container, Typography, Box, Snackbar, Alert, AlertTitle } from "@mui/material";
import styled from "@emotion/styled";
import RegisterForm from "../components/RegisterForm";
import Logo from "../components/Logo";
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";


// BUILD URL FOR REGISTER
const urladd = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API + "/api/human/add";

// some css stuff
const CenterLogin = styled("div")({
  height: "100vh",
  display: "grid",
  placeItems: "center",
  backgroundColor: "var(--bg-login)",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  color: "var(--headings-text)",
  backgroundColor: "var(--headings-fill)",
  accentColor: "var(--headings-text)",


});

const easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};


const Register = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const navigate = useNavigate(); // Use the hook inside the component
  const currentLocation = useLocation();
    
    // when form is submitted
    const handleSubmit = async (formData) => {

        
        const { email, password, confirmPassword } = formData;
        
        if ( password !== confirmPassword) { 
          setShowAlert(true);

         } else { 
          try {
          console.log(urladd, email, password);
          const response = await fetch(urladd, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ 
                "email": email,
                "password": password 
            }),
          });

          if (response.ok) {
            // Request was successful
            setShowAlertSuccess(true);
           

          } else {
              // Request failed
              const errorData = await response.json();
              setShowAlert(true);
              console.error(errorData);
          }
        } catch (error) {
          // Request error
          console.error("Error during API request:", error);
        }
      }};

      const handleCloseAlert = () => {
        setShowAlert(false);
      };

      const handleCloseAlertSuccess = () => {
          setShowAlertSuccess(false);
          navigate("/login", { state: { from: currentLocation }, replace: true });
      };

      

  return (
      <CenterLogin >
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>

            <Logo />
            
            <Typography sx={{ mb: 0, mt: 4, fontSize:22}}>
              Register
            </Typography>
          </HeadingStyle>

          {/*
           </ContentStyle>Box component={motion.div} {...fadeInUp}>
            <SocialAuth />
          </Box>

          <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUp}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider>
          */}

        <RegisterForm handleSubmit={handleSubmit} />

          <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            Already have an account? 
            <Link variant="subtitle2" href="/register" style={{ color: "var(--headings-text)" }}>
              &nbsp;Login
            </Link>
          </Typography>

           {/* Display the alert when showAlert is true */}
           <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}

          >
            <Alert 
             onClose={handleCloseAlert} 
             severity="error" 
             sx={{ width: '100%' }}
             >
            <AlertTitle>Error</AlertTitle>
              Passwords do not match or Address is already in use
            </Alert>
          </Snackbar>

           {/* Display the alert when showAlertSuccess is true */}
           <Snackbar
            open={showAlertSuccess}
            autoHideDuration={6000}
            onClose={handleCloseAlertSuccess}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}

          >
            <Alert 
             onClose={handleCloseAlertSuccess} 
             severity="success" 
             sx={{ width: '100%' }}
             >
            <AlertTitle>Success</AlertTitle>
              You are now registered! Please login
            </Alert>
          </Snackbar>
          
        </ContentStyle>
      </Container>
      </CenterLogin>
  );
};

export default Register;