import { useParams, useNavigate} from "react-router-dom";
// NEEDED THINGS
import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Container, Typography, Snackbar, Alert, AlertTitle } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// FOR THEME
import Grid from '@mui/material/Unstable_Grid2'; //new

// COMPONENTS
import HeaderAppBar from "../components/HeaderAppBar";

// CARD STUFF
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';


// LIST STUFF
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { styled } from '@mui/material/styles';

// OTHER STUFF
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AcUnitIcon from '@mui/icons-material/AcUnit'; //snowflake
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'; //airplane
import People from '@mui/icons-material/People'; //two-people
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'; //military
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; //stars
import AssignmentIcon from '@mui/icons-material/Assignment'; //notebook
import Diversity1Icon from '@mui/icons-material/Diversity1'; //fam
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'; //stroller
import HouseIcon from '@mui/icons-material/House'; //house
import CakeIcon from '@mui/icons-material/Cake'; //cake
import CelebrationIcon from '@mui/icons-material/Celebration'; //partyhat
import ChurchIcon from '@mui/icons-material/Church'; //church
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';


// CRUD HAPPINESS
import { getStuff } from '../services/get';
import { postItem, postFriend } from '../services/post';
import { deleteId, deleteFriend } from '../services/delete';
import { putList } from '../services/put';

// Helpers
import { getId } from '../helpers/getId.js';
import { getEmail } from '../helpers/getEmail.js';
import { permsList, permsHuman } from '../helpers/perms.js';


// DEFAULT FUNCTION *****************************************************************************************************************************************

export default function Wishlist() {

      // message that will display if loading, no perms or empty list
      const [message, setMessage] = useState("loading...");
      // If user can edit this list or not
      const [editstatus, setEditStatus] = useState("");
      // ID of the list we are looking at
      const { id } = useParams();

      // Get human email
      const humanEmail = Cookies.get("human_email");
      const [openDelItem, setOpenDelItem] = useState(false);
      // for page nav
      const navigate = useNavigate(); // Use inside the component


      // GET HUMAN ID OF LOGGED IN HUMAN *******************************************************************************************************
      const [humanId, setHumanId] = useState(null);
      useEffect(() => {
        getId(humanEmail)
          .then((dataid) => {
            setHumanId(dataid);
          })
          .catch((error) => {
            console.log('Error getting human ID:', error);
          });
      }, []);


      // SET LISTS AUTHED FOR THIS HUMAN **********************************************************************************************************
      const [listsauthed, setListsAuthed] = useState([]);
      useEffect(() => {
        if (humanId !== null) {
          //console.log("humanId ", humanId);
          permsList(humanId)
            .then((data) => {
              setListsAuthed(data);
              var i = 0;
              // Loop through and find if user is editor or viewer
              while ( i < data.length) {
                //Check if current row is this list
                if (String(data[i].list_id) === id ) { 
                  // Check edit status
                  if ( data[i].edit === 0 ) { setEditStatus("viewer"); }
                }
                i++;
              }
              
            })
            .catch((error) => {
              console.log('Error fetching data:', error);
              // Handle the error if necessary
            });
        }
      }, [humanId]); // Run this effect whenever humanId changes

         
      // SET HUMANS AUTHED FOR THIS LIST **********************************************************************************************************
      const [humansauthed, setHumansAuthed] = useState([]);
      function getHumansAuthed() {
            permsHuman(id)
            .then((data) => {
              setHumansAuthed(data);
            })
            .catch((error) => {
              console.log('Error fetching data:', error);
              // Handle the error if necessary
            });
 
      }
      useEffect(() => {
        if (id !== null) {
          getHumansAuthed();
        }
      }, [id]); // Run this effect whenever listid changes

    // GET ALL LISTS **********************************************************************************************************************************
    const [lists, setLists] = useState([]);
    function refreshLists() {
      getStuff("/api/lists?human_email=" + humanEmail)
      .then((data) => { setLists(data[0]); })
      .catch((error) => { console.error("Error fetching lists:", error); });
    }

      
    // GET THIS LIST DATA **************************************************************************************************************************
    const [listhuman, setListHuman] = useState(null);
    const [listname, setListName] = useState("");
    const [listicon, setListIcon] = useState("");
    const [originalsList, setOriginalsList] = useState([]);

    //friend stuff
    const [friendnew, setFriendNew] = useState("");
    const [friendnewedit, setFriendNewEdit] = useState(false);
    const [friendemails, setFriendEmails] = useState({});
    
    // items var
    const [items, setItems] = useState([]);

    function refreshListData() {
      getStuff("/api/lists?id=" + id)
      .then((data) => {
        setListName(data[0][0]['name']);
        setListIcon(data[0][0]['icon']);
        setListHuman(data[0][0]['human_id']);
      })
      .catch((error) => { console.error("Error fetching data for list:", error); });
    }

    // GET ITEMS ON THIS LIST *******************************************************************************************************************************
    const [itemid, setItemId] = useState(0);
    const [itemname, setItemName] = useState("");
    const [itempic, setItemPic] = useState("");
    const [itemurl, setItemUrl] = useState("");
    const [itempriority, setItemPriority] = useState("");
    const [itemprice, setItemPrice] = useState(0.00);
    const [itemdescript, setItemDescript] = useState("");
    const [itemIdToDelete, setItemIdToDelete] = useState(0);
    const [itemNameToDelete, setItemNameToDelete] = useState("");
    function refreshItems() {
        getStuff("/api/list/items?id=" + id)
        .then((data) => {
      if (data && data[0].length > 0) {
        // If data contains items, set them using setItems
        setItems(data[0]);
      } else {
        // If data is empty, set the message variable
        setMessage("This list is empty! Add something with the PLUS symbol in the header above");
      }
    })
        .catch((error) => { console.error("Error fetching items on list:", error); });
    }
    

// Check if user owns this list or is an authed friend ****************************************************************************************************************
function checkAuth() {

  // Check if user owns this list
  if (humanId && listhuman) {
    if (humanId === listhuman) {
      var isowner = true;
    } else {
      // Find the element if has matching human_id in humansauthed array
      if (humansauthed.find((user) => user.human_id === humanId)) {
        var isfriend = true;
      } else {
        setMessage("Not an authenticated friend. Sorry");
      }
    }
  } else {
    //console.log("Invalid humanId or listhuman");
  }
  if (isowner || isfriend) { 
    // list items since this person is authorized
    refreshItems(); setEditStatus("owner");
    //next check if this friend has edit access
    if (isfriend) { 
      setEditStatus("editor"); 
      //while (listsauthed.length < 10) {
        //text += "The number is " + i;
       //i++;
      //}
    }
  }
}

    // GET once on pageload
    useEffect(() => { 
      refreshListData();
      checkAuth();
      refreshLists();
      setOriginalsList([listname,listicon]);
      //if (isowner || isfriend) { refreshItems(); }
    }, [humanId,listhuman,id]);

    const handleUpdateFromComp = () => {
      refreshLists(); refreshItems();
    };
 
     
    // Alerts
    const [alertCustom, setAlertCustom] = useState(false);
    const [alertInfo, setAlertInfo] = useState(["","info","",""]);
    const handleCloseAlert = () => { setAlertCustom(false); };

    const StyledListItem = styled(ListItem)(({ theme }) => ({
      '&:hover': {
        backgroundColor: theme.palette.grey[200], // Change this to the desired background color
      },
    }));
  
    // ACTIONS ************************************************************************************************************************************************


    // Function to fetch the email for a given human_id
    const getEmailById = (id) => {
      // Call your getEmail function here and return the promise
      return getEmail(id);
    };

 // Fetch emails for all human_ids in the humansauthed array
 useEffect(() => {
  const fetchEmails = async () => {
    const emailsMap = {};
    for (const humanrow of humansauthed) {
      try {
        const email = await getEmailById(humanrow.human_id);
        emailsMap[humanrow.human_id] = email;
      } catch (error) {
        console.log('Error fetching email:', error);
        // Handle error if necessary
      }
    }
    setFriendEmails(emailsMap);
  };
  fetchEmails();
  }, [humansauthed]);

  function onSaveSuccess(what) {
    if (what == "item") {
      handleClose("itemadd");
      refreshItems();
      reset("itemadd");
    }
  }
  function onSaveError(error) {
    setAlertInfo(["GeneralError","error","Error",error]);
    setAlertCustom(true);
  }

    // function to make date/time human readable
    function formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
      };
    
      return date.toLocaleString('en-US', options);
    }

    // HANDLE DEL ITEM
    const handleDeleteItem = (itemId,theitemname) => {
      // Handle delete functionality
      setItemIdToDelete(itemId);
      setItemNameToDelete(theitemname);
      setOpenDelItem(true);
    };

    // HANDLE CONFIRM DEL ITEM
    const handleConfirmDelete = () => {
      deleteId(itemIdToDelete, "item", () => {
      setOpenDelItem(false);
      setItemIdToDelete(null); 
      setItemNameToDelete("");
      handleUpdateFromComp(); 
      });
    };

    // HANDLE CANCEL DEL ITEM
    const handleCancelDelete = () => {
      // Cancel the delete action
      setOpenDelItem(false);
      setItemIdToDelete(null); 
      setItemNameToDelete("");
    };

    // HANDLE DEL FRIEND
    const handleFriendDelete = (list_id, human_id) => {
      deleteFriend(list_id, human_id, () => {
        getHumansAuthed();
      });
    }

    const handlePageNav = (id) => {
      setMessage("Loading...");
      reset("items");
      navigate(`/wishlist/${id}`);
    };

    const handleGoPageHome = () => {
      navigate(`/`);
    }

    //Dialog open/close
    const [openItemAdd, setOpenItemAdd] = useState(false);
    const [openListEdit, setOpenListEdit] = useState(false);
    const [openFriendAdd, setOpenFriendAdd] = useState(false);

    // Handle open in Dialog popup clicked
    function handleClickOpen(what){
	    if (what === "itemadd") {setOpenItemAdd(true); }
      if (what === "listedit") {setOpenListEdit(true); }
	    if (what === "friend") {setOpenFriendAdd(true); }
    };

    // When form is closed or cancelled
    function handleClose(what){
	    if (what === "itemadd") {setOpenItemAdd(false); }
	    if (what === "listedit") {setOpenListEdit(false); }
	    if (what === "friend") {setOpenFriendAdd(false); }
    };
    

    // List Name change
    const handleListNameChange = (event) => { setListName(event.target.value); };
    function handleIconClick(icon) { setListIcon(icon); };

    // Item change or Add
    const handleItemNameChange = (event) => { setItemName(event.target.value); };
    const handleItemPicChange = (event) => { setItemPic(event.target.value); };
    const handleItemUrlChange = (event) => { setItemUrl(event.target.value); };
    const handleItemPriorityChange = (event) => { setItemPriority(event.target.value); };
    const handleItemPriceChange = (event) => { setItemPrice(event.target.value); };
    const handleItemDescriptChange = (event) => { setItemDescript(event.target.value); };

    // handle friend change
    const handleFriendNewChange = (event) => { setFriendNew(event.target.value); };
    const handleFriendNewEditChange = (event) => { setFriendNewEdit(event.target.checked); };

    function reset(what,originals){
      if (what === "items") {
        setItems([]);
      }
      if (what === "itemadd") {
        handleClose("itemadd");
        setItemId(0);
        setItemName("");
        setItemPic("");
        setItemUrl("");
        setItemPriority("");
        setItemPrice(0.00);
        setItemDescript("");
      }
      if (what === "listedit") {
        handleClose("listedit");
        setListName(originals[0]);
        setListIcon(originals[1]);
      }
      if (what === "friend") {
				handleClose("friend");
        setFriendNew("");
        setFriendNewEdit(false);
        getHumansAuthed();
			}
    }

    

  return (
    <Container maxWidth={"100%"} sx={{backgroundColor: "var(--bg-fill)", height: "100vh",}}>
        {/* TOP BAR */}
        <HeaderAppBar />
        <Grid container spacing={2} style={{ marginTop: '.5rem' }}>

        <Grid xs={12} md={9}>
            <Card sx={{ width: "100%", borderRadius: '20px', boxShadow: 5, color: 'var(--headings-text)'}}>
                <CardHeader sx={{ background: 'var(--col4)' }} titleTypographyProps={{variant:'h6' }} title={listname}
                    avatar={
                      <Avatar aria-label={listname} sx={{ background: 'var(--col1)' }} >
                          {listicon === "AssignmentIcon" && <AssignmentIcon /> }
                          {listicon === "AcUnitIcon" && <AcUnitIcon /> }
                          {listicon === "AirplanemodeActiveIcon" && <AirplanemodeActiveIcon /> }
                          {listicon === "People" && <People/> }
                          {listicon === "AutoAwesomeIcon" && <AutoAwesomeIcon /> }
                          {listicon === "MilitaryTechIcon" && <MilitaryTechIcon /> }
                          {listicon === "Diversity1Icon" && <Diversity1Icon /> }
                          {listicon === "ChildFriendlyIcon" && <ChildFriendlyIcon />}
                          {listicon === "CelebrationIcon" && <CelebrationIcon /> }
                          {listicon === "HouseIcon" && <HouseIcon /> }
                          {listicon === "CakeIcon" && <CakeIcon /> }
                          {listicon === "ChurchIcon" && <ChurchIcon /> }
                      </Avatar>}
                    action={
                    <Tooltip title="add item" placement="bottom-start">
                      <IconButton onClick={() => handleClickOpen("itemadd")} sx={{ p: 0 }} >
                        <AddCircleIcon sx={{ fontSize: 35, color: 'var(--headings-text)'}} />
                      </IconButton>
                    </Tooltip>
                    }
                />
                <CardContent sx={{ background: "white", color: "black" }}>
                    
                {items.map((itemrow) => (
                  
                  <List key={itemrow.id} sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper' }}>
                  <ListItem alignItems="flex-start">
                      <ListItemAvatar><Avatar sx={{ width: 80, height: 80, mr: 2, border: 1, borderColor: 'gray'}} alt="item pic" src={itemrow.pic}/></ListItemAvatar>
                      <ListItemText primary={itemrow.name} 
                        secondary={ 
                        <>
                          <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary" >
                            Price: {itemrow.price} Priority: {itemrow.priority}
                          </Typography>
                            &nbsp;
                            Date Added: {formatDateTime(itemrow.date_created)}<br />
                            {itemrow.description}<br />
                            <Button sx={{color: 'white', backgroundColor: 'var(--col1)', '&:hover': { backgroundColor: 'gray', },}} 
                              href={itemrow.iurl} size="small" target="_blank">
                                Open Link
                            </Button>
                        </>
                        }
                      />
                    <ListItemIcon onClick={() => handleDeleteItem(itemrow.id,itemrow.name)}>
                    <Tooltip title="delete item" placement="bottom-start">  
                      <DeleteIcon sx={{color: 'var(--col5)', '&:hover': { color: 'gray', },}} />
                    </Tooltip>
                    <Tooltip title="edit item" placement="bottom-start">  
                      <EditIcon sx={{color: 'var(--col4)', '&:hover': { color: 'gray', },}} />
                    </Tooltip>
                    </ListItemIcon>
                  </ListItem>
                   {/* Delete Confirmation Dialog */}
                   <Dialog open={openDelItem} onClose={handleCancelDelete}>
                        <DialogTitle>Delete Confirmation</DialogTitle>
                          <DialogContent>
                          <Typography variant="body1">
                            Are you sure you want to delete "{itemNameToDelete}"?
                          </Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleCancelDelete}>Cancel</Button>
                            <Button onClick={handleConfirmDelete} autoFocus>
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>      
                  </List>
                ))}

                    <Typography variant="body1" component="div">
                      {items.length === 0 && message}
                      {items.length === 0 && <><br /><br /></>} {/* Empty line */}
                    </Typography>

                  {/* DIALOG BOX */}
                  <Dialog open={openItemAdd} onClose={() => handleClose("itemadd")}>
                      <DialogTitle>Add item</DialogTitle>
                      <DialogContent sx={{ ml: "50px", mr: "20px"}}>

                          <TextField autoFocus margin="dense" id="addname"
                          onChange={handleItemNameChange}
                          helperText="Item Name: Numbers or letters only"
                          value={itemname} 
                          fullWidth variant="standard"
                          />

                          <TextField autoFocus margin="dense" id="addurl"
                          onChange={handleItemUrlChange}
                          helperText="Item URL"
                          value={itemurl} 
                          fullWidth variant="standard"
                          />

                         <TextField autoFocus margin="dense" id="addpic"
                          onChange={handleItemPicChange}
                          helperText="Item PIC URL"
                          value={itempic} 
                          fullWidth variant="standard"
                          />

                          <TextField autoFocus margin="dense" id="addpriority"
                          onChange={handleItemPriorityChange}
                          helperText="Priority"
                          value={itempriority} 
                          fullWidth variant="standard"
                          />

                          <TextField autoFocus margin="dense" id="addprice"
                          onChange={handleItemPriceChange}
                          helperText="Price"
                          value={itemprice} 
                          fullWidth variant="standard"
                          />                      
                      
                          <TextField autoFocus margin="dense" id="adddescript"
                          onChange={handleItemDescriptChange}
                          helperText="Description: Size? Color?"
                          value={itemdescript} 
                          fullWidth variant="standard"
                          />

                      </DialogContent>
                      <DialogActions>
                          <Button onClick={() => reset("itemadd")}>Cancel</Button>
                          <Button onClick={() => postItem(id,  humanId, itemname, itempic, itemurl, itempriority, itemprice, itemdescript, onSaveSuccess, onSaveError)}>Save</Button>
                      </DialogActions>
                  </Dialog>

                </CardContent>
            </Card>
        </Grid>

        <Grid xs={12} md={3}>
        <Card sx={{ width: "100%", borderRadius: '20px', boxShadow: 5, color: 'var(--headings-text)'}}>
                <CardHeader sx={{ background: 'var(--col3)' }} titleTypographyProps={{variant:'h6' }} title="edit list details"
                    avatar={
                      <Avatar aria-label={listname} sx={{ background: 'var(--col1)' }} >
                          <AssignmentIcon />
                      </Avatar>}
                />
                <CardContent sx={{ background: "white", color: "black" }}>
                <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
                     <ListItem alignItems="flex-start">
                        <ListItemAvatar sx={{width: 25, height: 25 }}>

			                      {listicon === "AssignmentIcon" && <AssignmentIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "AcUnitIcon" && <AcUnitIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "AirplanemodeActiveIcon" && <AirplanemodeActiveIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "People" && <People sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "AutoAwesomeIcon" && <AutoAwesomeIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "MilitaryTechIcon" && <MilitaryTechIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "Diversity1Icon" && <Diversity1Icon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "ChildFriendlyIcon" && <ChildFriendlyIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "CelebrationIcon" && <CelebrationIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "HouseIcon" && <HouseIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "CakeIcon" && <CakeIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
			                      {listicon === "ChurchIcon" && <ChurchIcon sx={{ fontSize: 25, color: "var(--col1)"}} /> }
                      
                  	</ListItemAvatar>
                  	<ListItemText primary={listname} 
                           secondary={
                           <>
                           {editstatus === "owner" && <>list owner<br /></>}
		                       {editstatus === "editor" && <>list editor<br /></>}
                           {editstatus === "viewer" && <>list viewer<br /></>}
                           </>
                           }
                        />
                       <ListItemIcon>
                          <Tooltip title="edit list details" placement="bottom-start">
                            <EditIcon onClick={() => handleClickOpen("listedit")} sx={{color: 'var(--col4)', width: 22, height: 22}} />
                          </Tooltip>
                       </ListItemIcon>
                     </ListItem>
                  </List>

                      {/* DIALOG BOX */}
                      <Dialog open={openListEdit} onClose={() => handleClose("listedit")}>
                      <DialogTitle>edit list</DialogTitle>
                      <DialogContent sx={{ ml: "50px", mr: "20px"}}>

                          <TextField autoFocus margin="dense" id="editlistname"
                          onChange={handleListNameChange}
                          helperText="List Name: Numbers or letters only"
                          value={listname} 
                          fullWidth variant="standard"
                          />
                          <br /><br />
                          <Typography variant="caption" sx={{ fontSize: 15}}>Choose icon</Typography><br />
                            <>
                              <ButtonGroup variant="outlined">
                                <Button onClick={() => handleIconClick("AssignmentIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
                                  {listicon === "AssignmentIcon" 
                                  ? <AssignmentIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
                                  : <AssignmentIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
                                </Button>
                                <Button onClick={() => handleIconClick("People")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
                                  {listicon === "People" 
                                  ? <People sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
                                  : <People sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
                                 </Button>
      <Button onClick={() => handleIconClick("AcUnitIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "AcUnitIcon" 
        ? <AcUnitIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AcUnitIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("AirplanemodeActiveIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "AirplanemodeActiveIcon" 
        ? <AirplanemodeActiveIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AirplanemodeActiveIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined">
      <Button onClick={() => handleIconClick("MilitaryTechIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "MilitaryTechIcon" 
        ? <MilitaryTechIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <MilitaryTechIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("AutoAwesomeIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "AutoAwesomeIcon" 
        ? <AutoAwesomeIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AutoAwesomeIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("Diversity1Icon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "Diversity1Icon" 
        ? <Diversity1Icon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <Diversity1Icon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("ChildFriendlyIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "ChildFriendlyIcon" 
        ? <ChildFriendlyIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <ChildFriendlyIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined">
      <Button onClick={() => handleIconClick("HouseIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "HouseIcon" 
        ? <HouseIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <HouseIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("CakeIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "CakeIcon" 
        ? <CakeIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <CakeIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("CelebrationIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "CelebrationIcon" 
        ? <CelebrationIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <CelebrationIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("ChurchIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "ChurchIcon" 
        ? <ChurchIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <ChurchIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
    </ButtonGroup>

    </>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={() => reset("listedit",originalsList)}>Cancel</Button>
                  <Button onClick={() => putList(id, listname, listicon, setAlertCustom, setAlertInfo, setOpenListEdit , refreshLists)}>Save</Button>
                </DialogActions>
                </Dialog> 

                </CardContent>
            </Card>
            <br />

            
            <Card sx={{ width: "100%", borderRadius: '20px', boxShadow: 5, color: 'var(--headings-text)'}}>
                <CardHeader sx={{ background: 'var(--col1)' }} titleTypographyProps={{variant:'h6' }} title="edit list access"
                    avatar={
                      <Avatar aria-label={listname} sx={{ background: 'var(--col1)' }} >
                          <AssignmentIcon />
                      </Avatar>}
                />
{/* EDIT LIST ACCESS CARD ON RIGHT */}
                <CardContent sx={{ background: "white", color: "black" }}>
                    <Typography variant="body1" component="div">

                    <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
  {editstatus === "owner" && humansauthed.map((humanrow) => (
    <ListItem alignItems="flex-start" key={humanrow.human_id}>
      <ListItemAvatar sx={{width: 28, height: 28 }}>
        <AlternateEmailIcon sx={{ fontSize: 28, color: "var(--col1)"}} />
      </ListItemAvatar>
      <ListItemText primary={friendemails[humanrow.human_id]}
        secondary={
          <>
            <Tooltip title="delete access" placement="bottom-start">  
              <DeleteIcon onClick={() => handleFriendDelete(id,humanrow.human_id)} sx={{color: 'var(--col5)', '&:hover': { color: 'gray', },}} />
            </Tooltip>
          </>
        }
      />
    </ListItem>
  ))}
  {editstatus === "owner" && (
    <ListItem alignItems="flex-start">
      <Button
        onClick={() => handleClickOpen("friend")}
        sx={{color: 'white', backgroundColor: 'var(--col4)', '&:hover': { backgroundColor: 'gray', },}} 
        size="small" target="_blank"
      >
        Send Invite
      </Button>
    </ListItem>
  )}
  {editstatus === "editor" && 
    <>
    <ListItem alignItems="flex-start">
    <ListItemText 
      primary="Sorry" 
      secondary="Only list owners can edit access"/>
    </ListItem>
    </>
  }
  {editstatus === "viewer" &&
    <>
    <ListItem alignItems="flex-start">
    <ListItemText 
      primary="Sorry" 
      secondary="Only list owners can edit access"/>
    </ListItem>
    </>
  }
</List>

                    </Typography>
                    {/* DIALOG BOX */}
                    <Dialog open={openFriendAdd} onClose={() => handleClose("friend")}>
	                    <DialogTitle>Invite Friend</DialogTitle>
                      <DialogContent sx={{ ml: "50px", mr: "20px"}}>

	                      <TextField autoFocus margin="dense" id="addemail"
	                        onChange={handleFriendNewChange}
                          helperText="Email: Must be a valid email"
                          value={friendnew} fullWidth variant="standard"/>

                        <FormControlLabel
			                    onChange={handleFriendNewEditChange}
			                    control={<Checkbox size="large" color="default" checked={friendnewedit}/>} 
			                    label="Edit wishlist access? (unchecked is view only)"/>
                          
                      </DialogContent>
                      <DialogActions>
	                      <Button onClick={() => reset("friend")}>Cancel</Button>
                        <Button onClick={() => postFriend(id, friendnew, friendnewedit, setAlertInfo, setAlertCustom, refreshLists, reset)}>Save</Button>
                      </DialogActions>
                    </Dialog>
                </CardContent>
            </Card> 
             <br />
            <Card sx={{ width: "100%", borderRadius: '20px', boxShadow: 5, color: 'var(--headings-text)'}}>
                <CardHeader sx={{ background: 'var(--col5)' }} titleTypographyProps={{variant:'h6' }} title="my lists"
                    avatar={
                      <Avatar aria-label={listname} sx={{ background: 'var(--col1)' }} >
                          <AssignmentIcon />
                      </Avatar>}
            />
                <CardContent sx={{ background: "white", color: "black" }}>

{/* MY LIST LOOP */}
{lists.length === 0 ? (
  <List>
    <ListItem alignItems="flex-start">
      <ListItemText 
        primary="Nothing to see here!" 
        secondary="You do not have any lists"/>
    </ListItem>
  </List>
  ) : (
    lists.map((mylistsrow) => (                  
                <List key={mylistsrow.id} sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
                    <StyledListItem alignItems="flex-start" onClick={() => handlePageNav(mylistsrow.id)}>
                        <ListItemAvatar sx={{width: 25, height: 25 }}>

			                      {mylistsrow.icon === "AssignmentIcon" && <AssignmentIcon sx={{fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "AcUnitIcon" && <AcUnitIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "AirplanemodeActiveIcon" && <AirplanemodeActiveIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "People" && <People sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "AutoAwesomeIcon" && <AutoAwesomeIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "MilitaryTechIcon" && <MilitaryTechIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "Diversity1Icon" && <Diversity1Icon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "ChildFriendlyIcon" && <ChildFriendlyIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "CelebrationIcon" && <CelebrationIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "HouseIcon" && <HouseIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "CakeIcon" && <CakeIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
			                      {mylistsrow.icon === "ChurchIcon" && <ChurchIcon sx={{ fontSize: 25, color: 'var(--col4)', cursor: 'pointer'}} /> }
                        </ListItemAvatar>
                  	<ListItemText primary={mylistsrow.name} sx={{ cursor: 'pointer'}}/>
                    </StyledListItem>
                </List>
)))}




                </CardContent>
            </Card>

            <br />
            <Card sx={{ width: "100%", borderRadius: '20px', boxShadow: 5, color: 'var(--headings-text)'}}>
                <CardHeader sx={{ background: 'var(--col4)' }} titleTypographyProps={{variant:'h6' }} title="links"
                    avatar={
                      <Avatar aria-label={listname} sx={{ background: 'var(--col1)' }} >
                          <AssignmentIcon />
                      </Avatar>}
                />
                <CardContent sx={{ background: "white", color: "black" }}>
                <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
                    <StyledListItem alignItems="flex-start" onClick={() => handleGoPageHome()}>
                    <ListItemText primary="Back to Main Page" sx={{ cursor: 'pointer'}} /><br />
                    </StyledListItem>
                </List>
                </CardContent>
            </Card>                               
        </Grid>

            {/* Display the alert when alertCustom is true */}
              <Snackbar
                open={alertCustom}
                autoHideDuration={6000}
                 onClose={handleCloseAlert}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
              <Alert 
                onClose={handleCloseAlert} 
                severity="error" 
                sx={{ width: '100%' }}
              >
              <AlertTitle>Error</AlertTitle>
                {alertInfo[3]}
                </Alert>
              </Snackbar>
        </Grid>
    </Container>
  );
}

