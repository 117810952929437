// Base API url
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;

export function getId(mail) {

    const apiPath = "/api/human/id?human_email=" + mail;

    return new Promise((resolve, reject) => {
        var newUrl = url + apiPath;
    
        fetch(newUrl)
          .then((response) => response.json())
          .then((data) => {
            if (data && data.data && data.data.length > 0) {
              resolve(data.data[0]['id']);
            } else if (data.data.length === 0) {
              resolve(0);
            } else {
              reject(new Error('Invalid response data'));
            }
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
            reject(error);
          });
      });
      
}