// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;

// Set vars
var newUrl = "";

// GET  *************************************************************************************
export async function getStuff(apiPath) {

  return new Promise((resolve, reject) => {
    newUrl = url + apiPath;
    console.log(apiPath);

    fetch(newUrl)
      .then((response) => response.json())
      .then((data) => {
        resolve([data.data]);
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
        reject(error);
      });
  });
  
}