// Base API url
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;

export function getEmail(id) {

    const apiPath = "/api/human/email?id=" + id;

    return new Promise((resolve, reject) => {
        var newUrl = url + apiPath;
    
        fetch(newUrl)
          .then((response) => response.json())
          .then((data) => {
            if (data && data.data && data.data.length > 0) {
              resolve(data.data[0]['email']);
            } else {
              reject(new Error('Invalid response data'));
            }
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
            reject(error);
          });
      });
      
}