// NEEDED THINGS
import { useNavigate} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Container, Typography, Snackbar, Alert, AlertTitle } from "@mui/material";

// FOR THEME
import Grid from '@mui/material/Unstable_Grid2'; //new

// COMPONENTS
import HeaderAppBar from "../components/HeaderAppBar";

// CARD STUFF
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

// OTHER STUFF
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

//Dialog popup
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// LIST STUFF
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { styled } from '@mui/material/styles';

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AcUnitIcon from '@mui/icons-material/AcUnit'; //snowflake
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'; //airplane
import People from '@mui/icons-material/People'; //two-people
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'; //military
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; //stars
import AssignmentIcon from '@mui/icons-material/Assignment'; //notebook
import Diversity1Icon from '@mui/icons-material/Diversity1'; //fam
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'; //stroller
import HouseIcon from '@mui/icons-material/House'; //house
import CakeIcon from '@mui/icons-material/Cake'; //cake
import CelebrationIcon from '@mui/icons-material/Celebration'; //partyhat
import ChurchIcon from '@mui/icons-material/Church'; //church


// CRUD HAPPINESS
import { getStuff } from '../services/get';
import { postList } from '../services/post';


// Helpers
import { getId } from '../helpers/getId.js';
import { permsList } from '../helpers/perms.js';

// COMPONENTS
import GalleryCard from '../components/GalleryCard.js';



// DEFAULT FUNCTION *****************************************************************************************************************************************

export default function HomePage() {

    // Get human email and id
    const humanEmail = Cookies.get("human_email");
    const [humanId, setHumanId] = useState(null);
    var emptymessage = "You don't have any lists yet! Make one with the PLUS symbol in the header above";

    // for page nav
    const navigate = useNavigate(); // Use inside the component

    const StyledListItem = styled(ListItem)(({ theme }) => ({
      '&:hover': {
        backgroundColor: theme.palette.grey[200], // Change this to the desired background color
      },
    }));
   
    useEffect(() => {
      getId(humanEmail)
        .then((id) => {
          setHumanId(id);
        })
        .catch((error) => {
          console.log('Error getting human ID:', error);
        });
    }, []);

    // SET LISTS AUTHED FOR THIS HUMAN **********************************************************************************************************
    const [listsauthed, setListsAuthed] = useState([]);
    const [listNames, setListNames] = useState({});
    const [listIcons, setListIcons] = useState({});

    useEffect(() => {
      if (humanId !== null && humanId !== 0) {
        console.log("humanId ", humanId);
        permsList(humanId)
          .then((data) => {
            setListsAuthed(data);
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
          });
      }
    }, [humanId]); // Run this effect whenever humanId changes

    useEffect(() => {
      // Get list name for each list ID
      const fetchListNames = async () => {
        const newNames = {};
        for (const authedrow of listsauthed) {
          try {
            const data = await getStuff("/api/lists?id=" + authedrow.list_id);
            const listName = data[0][0]['name'];
            newNames[authedrow.list_id] = listName;
          } catch (error) {
            console.error("Error fetching data for list:", error);
          }
        }
        setListNames(newNames);
      };
      // Fetch the list names only if listsauthed is not empty
    if (listsauthed.length > 0) {
      fetchListNames();
    }
  }, [listsauthed]);


  useEffect(() => {
    // Get list icon for each list ID
    const fetchListIcons = async () => {
      const newIcons = {};
      for (const authedrow of listsauthed) {
        try {
          const data = await getStuff("/api/lists?id=" + authedrow.list_id);
          const listIcon = data[0][0]['icon'];
          newIcons[authedrow.list_id] = listIcon;
        } catch (error) {
          console.error("Error fetching icon for list:", error);
        }
      }
      setListIcons(newIcons);
    };
    // Fetch the list names only if listsauthed is not empty
  if (listsauthed.length > 0) {
    fetchListIcons();
  }
}, [listsauthed]);

    // GET MY LISTS
    const [lists, setLists] = useState([]);
    function refreshLists() {
      getStuff("/api/lists?human_email=" + humanEmail)
      .then((data) => { setLists(data[0]); })
      .catch((error) => { console.error("Error fetching lists:", error); });
    }
    // GET once on pageload
    useEffect(() => { refreshLists(); }, []);

    const handleUpdateFromComp = () => {
      refreshLists();
    };

    // vars
    const [listnew, setListNew] = useState("");
    const [listicon, setListIcon] = useState("");
    const [friendnew, setFriendNew] = useState("");
    // Alerts
    const [alertCustom, setAlertCustom] = useState(false);
    const [alertInfo, setAlertInfo] = useState(["","info","",""]);
    const handleCloseAlert = () => { setAlertCustom(false); };
    

    // ACTIONS ************************************************************************************************************************************************

    //Dialog open/close
    const [openListNew, setOpenListNew] = useState(false);
    const [openFriendNew, setOpenFriendNew] = useState(false);

    // Handle open in Dialog popup clicked
    function handleClickOpen(what){
      if (what === "listnew") {setOpenListNew(true); }
      if (what === "friendnew") {setOpenFriendNew(true); }
    };
  
    // When form is closed or cancelled
    function handleClose(what){
      if (what === "listnew") {setOpenListNew(false); }
      if (what === "friendnew") {setOpenFriendNew(false); }
    };

    const handlePageNav = (id) => {
      //setMessage("Loading...");
      //reset("items");
      navigate(`/wishlist/${id}`);
    };

    // LISTS: List change
    const handleListChange = (event) => { setListNew(event.target.value); };
    // LISTS: Friend ADD field change
    const handleFriendChange = (event) => { setFriendNew(event.target.value); };
    // when an icon is clicked in list new screen
    function handleIconClick(icon) { setListIcon(icon); };

    // When reset a dialog form is needed
    function reset(what) {
      if (what === "listnew") { setListNew(""); handleClose("listnew");}
      if (what === "friendnew") { setFriendNew(""); handleClose("friendnew");}
    };
  
    return(
  
      <Container maxWidth={"100%"} sx={{backgroundColor: "var(--bg-fill)", height: "100vh",}}>

      {/* TOP BAR */}
      <HeaderAppBar humanId={humanId}/>
        <Grid container spacing={2} style={{ marginTop: '.5rem' }}>

            {/* MINE */}
            <Grid xs={12} md={8}>
            {/* List Loop */}
            <Card sx={{ width: "100%", borderRadius: '20px', boxShadow: 5, color: 'var(--headings-text)'}}>
                <CardHeader sx={{ background: 'var(--col3)' }} titleTypographyProps={{variant:'h6' }} title="my wishlists"
                action={
                  <Tooltip title="add wishlist" placement="bottom-start">
                    <IconButton onClick={() => handleClickOpen("listnew")} sx={{ p: 0 }} >
                      <AddCircleIcon sx={{ fontSize: 35, color: 'var(--headings-text)'}} />
                    </IconButton>
                  </Tooltip>
                }
                />
                <CardContent sx={{ background: "white", color: "black" }}>

                <Typography variant="body1" component="div">
                  {lists.length === 0 && emptymessage}
                  {lists.length === 0 && <><br /><br /></>} {/* Empty line */}
                </Typography>

                <Grid container spacing={5}>
                  {lists.map((listrow, index) => (
                      <GalleryCard
                        key={listrow.id}
                        id={listrow.id}
                        lname={listrow.name}
                        licon={listrow.icon}
                        lcolor={index % 2 === 0 ? '2d5e74' : 'be4e32'}
                        lcount={lists.length}
                        index={index}
                        handleUpdateFromComp={handleUpdateFromComp}                        
                      />

                  ))}
                  </Grid>

                 
                {/* DIALOG BOX */}
                <Dialog open={openListNew} onClose={() => handleClose("listnew")}>
                  <DialogTitle>Add wish list</DialogTitle>
                  <DialogContent sx={{ ml: "50px", mr: "20px"}}>
                  <TextField autoFocus margin="dense" id="addlist"
                    onChange={handleListChange}
                    helperText="Text: Numbers or letters only"
                    value={listnew} 
                    fullWidth variant="standard"
                  />
                  <br /><br />
          <Typography variant="caption" sx={{ fontSize: 15}}>
            Choose icon
          </Typography>
          <br />
          <>
    <ButtonGroup variant="outlined">
      <Button onClick={() => handleIconClick("AssignmentIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "AssignmentIcon" 
        ? <AssignmentIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AssignmentIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("People")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "People" 
        ? <People sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <People sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("AcUnitIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "AcUnitIcon" 
        ? <AcUnitIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AcUnitIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("AirplanemodeActiveIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "AirplanemodeActiveIcon" 
        ? <AirplanemodeActiveIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AirplanemodeActiveIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined">
      <Button onClick={() => handleIconClick("MilitaryTechIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "MilitaryTechIcon" 
        ? <MilitaryTechIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <MilitaryTechIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("AutoAwesomeIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "AutoAwesomeIcon" 
        ? <AutoAwesomeIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <AutoAwesomeIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("Diversity1Icon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "Diversity1Icon" 
        ? <Diversity1Icon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <Diversity1Icon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("ChildFriendlyIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "ChildFriendlyIcon" 
        ? <ChildFriendlyIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <ChildFriendlyIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
    </ButtonGroup>
    <br />
    <ButtonGroup variant="outlined">
      <Button onClick={() => handleIconClick("HouseIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "HouseIcon" 
        ? <HouseIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <HouseIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("CakeIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "CakeIcon" 
        ? <CakeIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <CakeIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("CelebrationIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "CelebrationIcon" 
        ? <CelebrationIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <CelebrationIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
      <Button onClick={() => handleIconClick("ChurchIcon")} sx={{color:"white", backgroundColor:"var(--col1)", ":hover":{ backgroundColor:"gray", opacity:0.9 }, borderRadius:0, border:"1px solid white", width:"25px"}}>
        {listicon === "ChurchIcon" 
        ? <ChurchIcon sx={{ backgroundColor: "var(--menu-link-active)", fontSize: 22 }}/> 
        : <ChurchIcon sx={{ backgroundColor: "var(--menu-link-inactive)", fontSize: 22 }}/>}
      </Button>
    </ButtonGroup>

    </>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={() => reset("listnew")}>Cancel</Button>
                  <Button onClick={() => postList(listnew, listicon, setAlertCustom, setAlertInfo, setOpenListNew, refreshLists, reset, humanId)}>Save</Button>
                </DialogActions>
                </Dialog> 
              </CardContent>
            </Card>

            </Grid>

            {/* FRIENDS */}
            <Grid xs={12} md={4}>
                {/* List Loop */}
            <Card sx={{ width: "100%", borderRadius: '20px', boxShadow: 5, color: 'var(--headings-text)'}}>
                <CardHeader sx={{ background: 'var(--col1)' }} titleTypographyProps={{variant:'h6' }} title="friend's wishlists"
                action={
                  <Tooltip title="imvite friend" placement="bottom-start">
                    <IconButton onClick={() => handleClickOpen("friendnew")} sx={{ p: 0 }} >
                      <AddCircleIcon sx={{ fontSize: 35, color: 'var(--headings-text)'}} />
                    </IconButton>
                  </Tooltip>
                }
                />
                <CardContent sx={{ background: 'var(--headings-text)', color: "black" }}>
                {listsauthed.length === 0 ? (
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemText 
                        primary="Nothing to see here!" 
                        secondary="You do not have access to any friend's wish lists :("/>
                      </ListItem>
                  </List>
                ) : (
                listsauthed.map((authedrow) => (                
                <List key={authedrow.list_id} sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
                    <StyledListItem key={authedrow.list_id} alignItems="flex-start" onClick={() => handlePageNav(authedrow.list_id)}>
                        <ListItemAvatar sx={{width: 25, height: 25 }}>
			                      {listIcons[authedrow.list_id] === "AssignmentIcon" && <AssignmentIcon sx={{fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "AcUnitIcon" && <AcUnitIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "AirplanemodeActiveIcon" && <AirplanemodeActiveIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "People" && <People sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "AutoAwesomeIcon" && <AutoAwesomeIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "MilitaryTechIcon" && <MilitaryTechIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "Diversity1Icon" && <Diversity1Icon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "ChildFriendlyIcon" && <ChildFriendlyIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "CelebrationIcon" && <CelebrationIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "HouseIcon" && <HouseIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "CakeIcon" && <CakeIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
			                      {listIcons[authedrow.list_id] === "ChurchIcon" && <ChurchIcon sx={{ fontSize: 25, color: 'var(--col4)'}} /> }
                        </ListItemAvatar>
                  	<ListItemText primary={listNames[authedrow.list_id]}/>
                    </StyledListItem>
                </List>
                )))}
                   
                </CardContent>
             </Card>
            </Grid>

           {/* Display the alert when alertCustom is true */}
           <Snackbar
            open={alertCustom}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}

          >
            <Alert 
             onClose={handleCloseAlert} 
             severity="error" 
             sx={{ width: '100%' }}
             >
            <AlertTitle>Error</AlertTitle>
           {alertInfo[3]}
            </Alert>
          </Snackbar>

        </Grid>
      </Container>

 
  );
}