// HELPERS
import { validateEmail, validateTextEmail, validateClean} from '../helpers/validate';
import { getId } from '../helpers/getId.js';
import { unstable_composeClasses } from '@mui/utils';

// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
// Set vars
var newUrl = "";


// POST LIST ****************************************************************************************************************************
               
export async function postList(listnew, listicon, setAlertCustom, setAlertInfo, setOpenListNew, refreshLists, reset, humanId) {

  newUrl = url + "/api/lists"

  if (listicon === null || listicon === "") { var listicon = "AssignmentIcon";}

  if (validateClean(listnew)) {
    return new Promise((resolve, reject) => {
      setOpenListNew(false);
  
      fetch(newUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: listnew,
          human_id: humanId,
          icon: listicon,
        }),
      })
        .then((response) => {
          if (response.ok) {
            // Request was successful
            refreshLists(); // Call refreshTags to update the data in components
            reset("listnew");
          } else {
            // Request failed
            throw new Error("Request failed");
          }
        })
        .catch((error) => {
          // Request error
          console.error("Error during API request:", error);
          setAlertInfo(["GeneralError", "error", "Error", error]);
          setAlertCustom(true);
        });
    });
  } else {
    setAlertInfo(["invalidText", "error", "Error", "You may enter only letters and numbers"]);
    setAlertCustom(true);
  }
  
}

// POST ITEM ****************************************************************************************************************************
               
export async function postItem(list_id, humanId, itemname, itempic, itemurl, itempriority, itemprice, itemdescript, onSaveSuccess, onSaveError) {
  newUrl = url + "/api/list/items"

  console.log("body ", list_id, itemname, itempic, itemurl, itempriority, itemprice, itemdescript, humanId);

   if (validateClean(itemname)) {
    return new Promise((resolve, reject) => {
  
      fetch(newUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          list_id: list_id,
          name: itemname,
          pic: itempic,
          human_id: humanId,
          iurl: itemurl,
          priority: itempriority,
          price: itemprice,
          description: itemdescript,
        }),
      })
        .then((response) => {
          if (response.ok) {
            // Request was successful
            onSaveSuccess("item");
          } else {
            // Request failed
            onSaveError("Request failed");
            //throw new Error("Request failed");
          }
        })
        .catch((error) => {
          // Request error
          onSaveError(error);
          console.error("Error during API request:", error);
        });
    });
  } else {
    onSaveError("You may enter only letters and numbers");
  }
  
}

// POST FRIEND ****************************************************************************************************************************

export async function postFriend(list_id, friendnew, friendnewedit, setAlertInfo, setAlertCustom, refreshList, reset) {
  const newUrl = url + "/api/friend";

  // Make sure this is a valid email first
  if (validateEmail(friendnew)) {
    try {
      // Get friend id
      const friendid = await getId(friendnew);

      if (friendid) {
        const response = await fetch(newUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            list_id: list_id,
            human_id: friendid,
            edit: friendnewedit,
          }),
        });

        if (response.ok) {
          // Request was successful
          refreshList(); // Call refreshItems to update the data in components
          reset("friend");
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      } else {
        // Friend doesn't exist
        setAlertInfo(["invalidText", "error", "Error", "This user doesn't exist. They must register first!"]);
        setAlertCustom(true);
        reset("friend");
      }
    } catch (error) {
      // Request error
      console.error("Error during API request:", error);
    }
  } else {
    setAlertInfo(["invalidText", "error", "Error", "You may enter only a valid email"]);
    setAlertCustom(true);
  }
}


