import React from "react";
import ReactDOM from "react-dom/client";
import './index.css'
import App from "./App";
import { BrowserRouter } from "react-router-dom";

// Get the container element
const container = document.getElementById("root");

// Create the root if it doesn't exist
const root = container._reactRootContainer
  ? container._reactRootContainer._internalRoot
  : ReactDOM.createRoot(container);

// Render the app
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);