import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; //new
import { Route, Routes, Navigate, useLocation, useNavigate} from "react-router-dom";

// DIALOG STUFF
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

// CARD STUFF
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// LIST STUFF
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// ICONS
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Icons for wishlists
import AcUnitIcon from '@mui/icons-material/AcUnit'; //snowflake
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'; //airplane
import People from '@mui/icons-material/People'; //two-people
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'; //military
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; //stars
import AssignmentIcon from '@mui/icons-material/Assignment'; //notebook
import Diversity1Icon from '@mui/icons-material/Diversity1'; //fam
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'; //stroller
import HouseIcon from '@mui/icons-material/House'; //house
import CakeIcon from '@mui/icons-material/Cake'; //cake
import CelebrationIcon from '@mui/icons-material/Celebration'; //partyhat
import ChurchIcon from '@mui/icons-material/Church'; //church

// services
import { deleteId } from '../services/delete';
import { getStuff } from '../services/get';

export default function GalleryCard({id, lname, licon, lcolor, lcount, index, handleUpdateFromComp}) {

    // add hash in front of color
    var colorHeader = '#' + String(lcolor);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate(); // Use the hook inside the component
    // message that will display if loading, no perms or empty list
    const [message, setMessage] = useState("loading...");
    const [items, setItems] = useState([]);

    function refreshItems() {
      getStuff("/api/list/items?id=" + id)
      .then((data) => {
    if (data && data[0].length > 0) {
      // If data contains items, set them using setItems
      setItems(data[0]);
    } else {
      // If data is empty, set the message variable
      setMessage("This list is empty!");
    }
  })
      .catch((error) => { console.error("Error fetching items on list:", error); });
  }

  // GET once on pageload
  useEffect(() => { 
    refreshItems();      
  }, []);
  
    
    // Responsive grid according to number of lists ***********************************************************************************************************

    let gridProps = {xs: 12, sm: 6, md: 4, lg: 3, xl: 2, };

    if (lcount === 1) {
        gridProps = {xs: 12, sm: 12, md: 12, lg: 12, xl: 12, };
    } else if (lcount === 2) {
        gridProps = {xs: 12, sm: 6, md: 6, lg: 6, xl: 6, };
    } else if (lcount >= 3) {
        gridProps = {xs: 12, sm: 6, md: 4, lg: 4, xl: 4, };
    }

      // ACTIONS *********************************************************************************************************************************************

     
      const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleMenuClose = () => {
        setAnchorEl(null);
      };
    
      const handleView = (id) => {
        navigate(`/wishlist/${id}`);
        handleMenuClose();
      };
    
      const handleDelete = () => {
        // Handle delete functionality
        setOpenDialog(true);
        handleMenuClose();
      };

      const handleConfirmDelete = () => {
        // Handle delete functionality
        deleteId(id, "list", handleUpdateFromComp);
        setOpenDialog(false);
      };

      const handleCancelDelete = () => {
        // Cancel the delete action
        setOpenDialog(false);
      };

      // DEFAULT FUNCTION **********************************************************************************************
    return (
        <>
        <Grid {...gridProps}>

            <Card sx={{ width: "100%", borderRadius: '10px', boxShadow: 4, color: 'white'}}>
                <CardHeader sx={{ background: colorHeader}} titleTypographyProps={{variant:'h6' }} 
                    title={
                      <Typography variant="h6" onClick={() => handleView(id)} sx={{cursor: 'pointer'}}>
                        {lname}
                      </Typography>
                    } 
                    avatar={
                        <Avatar aria-label={lname} sx={{ background: 'var(--col1)' }} >
                            {licon === "AssignmentIcon" && <AssignmentIcon /> }
                            {licon === "AcUnitIcon" && <AcUnitIcon /> }
                            {licon === "AirplanemodeActiveIcon" && <AirplanemodeActiveIcon /> }
                            {licon === "People" && <People/> }
                            {licon === "AutoAwesomeIcon" && <AutoAwesomeIcon /> }
                            {licon === "MilitaryTechIcon" && <MilitaryTechIcon /> }
                            {licon === "Diversity1Icon" && <Diversity1Icon /> }
                            {licon === "ChildFriendlyIcon" && <ChildFriendlyIcon />}
                            {licon === "CelebrationIcon" && <CelebrationIcon /> }
                            {licon === "HouseIcon" && <HouseIcon /> }
                            {licon === "CakeIcon" && <CakeIcon /> }
                            {licon === "ChurchIcon" && <ChurchIcon /> }
                        </Avatar>}
                    action={ <IconButton aria-label="settings" onClick={handleMenuOpen}> <MoreVertIcon sx={{ color: 'white'}} /> </IconButton> }
                    />
                <CardContent sx={{ background: "white", color: "black" }}>

                {items.slice(0, 2).map((itemrow) => (
                  
                  <List key={itemrow.id} sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper' }}>
                  <ListItem alignItems="flex-start">
                      <ListItemAvatar><Avatar sx={{ width: 80, height: 80, mr: 2, border: 1, borderColor: 'gray'}} alt="Remy Sharp" src={itemrow.pic}/></ListItemAvatar>
                      <ListItemText primary={itemrow.name} 
                        secondary={ 
                        <>
                            <Button size="small" 
                              sx={{color: 'white', backgroundColor: 'var(--col1)', '&:hover': { backgroundColor: 'gray', },}} 
                              href={itemrow.iurl} size="medium" target="_blank">
                                Open Link
                            </Button>
                        </>
                        }
                      />
                  </ListItem>
                  </List>
                ))}
                <Typography variant="body1" component="div">
                  {items.length === 0 && message}
                  {items.length === 0 && <><br /><br /></>} {/* Empty line */}
                </Typography>
                {items.length === 0 &&
                <Button size="small" onClick={() => handleView(id)}
                  sx={{color: 'white', backgroundColor: 'var(--col3)', '&:hover': { backgroundColor: 'gray', },}} 
                  size="medium" target="_blank" >Open
                </Button>
                }
                {items.length > 0 &&
                <Button size="small" onClick={() => handleView(id)}
                  sx={{color: 'white', backgroundColor: 'var(--col3)', '&:hover': { backgroundColor: 'gray', },}} 
                  size="medium" target="_blank" >More
                </Button>
                }

                </CardContent>
            </Card>
        </Grid>

        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        >
        <MenuItem onClick={() => handleView(id)}>View</MenuItem>
        <MenuItem onClick={() => handleDelete(id)}>Delete</MenuItem>
      </Menu>

        {/* Delete Confirmation Dialog */}
        <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete "{lname}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

        </>
    );
}