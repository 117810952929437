// HELPERS
import { validateClean} from '../helpers/validate';

// Base API url for Admin -> General
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;
// Set vars
var newUrl = "";


// PUT EMAILS ****************************************************************************************************************************

export async function putEmails(id, value, enabled, extra, setAlertCustom, setAlertInfo) {
    var apiPath = "/api/admin/general";
    newUrl = url + `${apiPath}?id=${id}&value='${value}'&enabled=${enabled}&extra='${extra}'`;

    try {
      fetch(newUrl, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            // Request was successful
          } else {
            // Request failed
            throw new Error("Request failed");
          }
        })
        .catch((error) => {
          // Request error
          console.error("Error during API request:", error);
          setAlertInfo(["GeneralError","error","Error",error]);
          setAlertCustom(true);
        });
    } catch (error) {
      // Request error
      console.error("Error during API request:", error);
      setAlertInfo(["GeneralError","error","Error",error]);
      setAlertCustom(true);
    }
  };


// PUT TAGS ****************************************************************************************************************************

export async function putTags(id, tag, color, txtcolor, setAlertCustom, setAlertInfo) {
  var apiPath = "/api/admin/tags";
  newUrl = url + `${apiPath}?id=${id}&tag='${tag}'&color='${color}'&txtcolor='${txtcolor}'`;

  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          // Request was successful
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    setAlertInfo(["GeneralError","error","Error",error]);
    setAlertCustom(true);
  }
};

// PUT DEPTS ****************************************************************************************************************************

export async function putDepts(id, name, icon, setAlertCustom, setAlertInfo, handleUpdateFromComp) {
  var apiPath = "/api/admin/depts";
  newUrl = url + `${apiPath}?id=${id}&name='${name}'&icon='${icon}'`;

  try {
    fetch(newUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          handleUpdateFromComp("gd");
        } else {
          // Request failed
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        // Request error
        console.error("Error during API request:", error);
        setAlertInfo(["GeneralError","error","Error",error]);
        setAlertCustom(true);
      });
  } catch (error) {
    // Request error
    console.error("Error during API request:", error);
    setAlertInfo(["GeneralError","error","Error",error]);
    setAlertCustom(true);
  }
};


// PUT LIST ****************************************************************************************************************************

export async function putList(id, listname, listicon, setAlertCustom, setAlertInfo, setOpenListEdit, refreshLists) {
  var apiPath =url + "/api/lists";
  
  if (listicon === null || listicon === "") { var listicon = "AssignmentIcon";}
  newUrl = `${apiPath}?id=${id}&name='${listname}'&icon='${listicon}'`;


  if (validateClean(listname)) {
    try {
      fetch(newUrl, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            setOpenListEdit(false);
            refreshLists();
          } else {
            // Request failed
            throw new Error("Request failed");
          }
        })
        .catch((error) => {
          // Request error
          console.error("Error during API request:", error);
          setAlertInfo(["GeneralError","error","Error",error]);
          setAlertCustom(true);
        });
    } catch (error) {
      // Request error
      console.error("Error during API request:", error);
      setAlertInfo(["GeneralError","error","Error",error]);
      setAlertCustom(true);
      }
   } else {
    setAlertInfo(["invalidText", "error", "Error", "You may enter only letters and numbers"]);
    setAlertCustom(true);
  }
  
 } 