import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import styled from "styled-components";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "var(--headings-text)",
  },
  "& .MuiInputLabel-root": {
    color: "var(--headings-text)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--headings-text)",
    },
    "&:hover fieldset": {
      borderColor: "var(--menu-link-inactive)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--menu-link-inactive)",
    },
    "& input[type='password']": {
      color: "var(--menu-link-inactive)",
    },
    "& .password-input": {
      color: "var(--menu-link-inactive)",
    },
    "& input[type='email']": {
      color: "var(--menu-link-inactive)",
    },
  },
});

const RegisterForm = ({ handleSubmit }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const { email, password, confirmPassword } = event.target.elements;

    handleSubmit({
      email: email.value,
      password: password.value,
      confirmPassword: confirmPassword.value
    });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Box
        component={motion.div}
        animate={{
          transition: {
            staggerChildren: 0.55,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
          component={motion.div}
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <CssTextField
            sx={{ my: 2 }}
            fullWidth
            type="email"
            label="Email Address"
            name="email"
          />

          <CssTextField
            fullWidth
            type={showPassword ? "text" : "password"}
            label="Password"
            name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? (
                      <Icon icon="eva:eye-fill" style={{ color: "var(--icon-action)" }} />
                    ) : (
                      <Icon
                        icon="eva:eye-off-fill"
                        style={{ color: "white" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              className: showPassword ? "password-input" : "",
            }}
          />

          <CssTextField
            fullWidth
            type={showPassword ? "text" : "password"}
            label="Confirm Password"
            name="confirmPassword"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? (
                      <Icon icon="eva:eye-fill" style={{ color: "var(--icon-action)" }} />
                    ) : (
                      <Icon
                        icon="eva:eye-off-fill"
                        style={{ color: "white" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              className: showPassword ? "password-input" : "",
            }}
          />
        </Box>

        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >   
        <br/><br/>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: 'var(--col3)', // Button color
              '&:hover': {
                backgroundColor: 'var(--col4)', // Hover color
              },
            }}
          >
          Register
          </LoadingButton>
          
        </Box>
      </Box>
    </form>
  );
};

export default RegisterForm;