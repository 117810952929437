import { useState, useEffect } from "react";
import { CssBaseline, Typography } from "@mui/material";
import { Route, Routes, Navigate, useLocation, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import Wishlist from "./pages/Wishlist";
//import Forgot from "./pages/Forgot";
import Register from "./pages/Register";

function App() {
  const [auth, setAuth] = useState(false);
  const [sessionChecked, setSessionChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Use the hook inside the component

  // BUILD URL FOR LOGIN (SESSION ACTIVE CHECK)
  const urllogin = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API + "/api/human/session";
  
  useEffect(() => {
    const checkSession = async () => {

      // paths that dont need a session
      const openPaths = ['/register'];

      // Check if the current path requires authentication
      if (openPaths.includes(location.pathname)) {
        setSessionChecked(true);
        return; // Skip session check for open paths
      }
      

      // Check if session cookies exist
      const humanEmailCookie = Cookies.get("human_email");
      const humanSessionCookie = Cookies.get("human_session");

       if (humanEmailCookie && humanSessionCookie) {
        try {
          const response = await fetch(urllogin, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "humanEmailCookie": humanEmailCookie,
              "humanSessionCookie": humanSessionCookie,
            }),
          });

          if (response.ok) {
            // If session is valid, set auth to true
            const responseData = await response.json();
            const sessCheck = responseData.sessCheck;
           
            if (sessCheck === true) {
              setAuth(true);    
            } else {
              setAuth(false);
            }
            
          } else {
            // Session is invalid, navigate to login page
            navigate("/login", { replace: true });
          }
        } catch (error) {
          console.error("Error during API request:", error);
        }
      } else {
        // Session cookies not found, navigate to login page
        navigate("/login", { replace: true });
      }

     // Set the session check completion state to true
     setSessionChecked(true);
  };

    checkSession();
  }, [location.pathname]);
  
  // If session is not checked yet, show a loading indicator or placeholder
  if (!sessionChecked) {
    return <Typography variant="h5" component="div" sx={{color: 'var(--col4)', ml: 2, mt: 1}}>Loading...</Typography>;
  }

  // Render the Routes component only when the session check is completed
  return (
    <>
      <CssBaseline />
      {sessionChecked && (
      <Routes>
        <Route path="/login" element={<Login setAuth={setAuth} />} />
        <Route
          path="/"
          element={
            auth ? (
              <HomePage setAuth={setAuth}/>
            ) : (
              <Navigate to="/login" state={{ from: location }} replace />
            ) 
          }
        />
         {auth && <Route path="/wishlist/:id" element={<Wishlist />} />}
         <Route path="/register" element={<Register />} />
      </Routes>
      )}
    </>
  );
}

export default App;