import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import logo from '../static/images/logo.png';


const Logo = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Link to="/">
        
        <Box component="img" src={logo} alt="logo" style={{ maxHeight: 60, alignSelf: "center"}} />
        
      </Link>
    </Box>
  );
};

export default Logo;