// Base API url
const url = process.env.REACT_APP_SCHEME + "://" + process.env.REACT_APP_API;



export function permsHuman(list_id) {
  const apiPath = "/api/perms_human?list_id=" + list_id;

  return new Promise((resolve, reject) => {
    var newUrl = url + apiPath;

    fetch(newUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          // Check if the data property is an array
          if (Array.isArray(data.data)) {
            resolve(data.data); // Resolve with the array if it exists
          } else {
            
            reject(new Error('Invalid response data'));
          }
        } else {
         
          resolve([]); // Resolve with an empty array if data.data is not available
        }
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
        reject(error);
      });
  });
}





export function permsList(human_id) {
  //console.log("human_id ", human_id);

  const apiPath = "/api/perms_list?human_id=" + human_id;

  return new Promise((resolve, reject) => {
      var newUrl = url + apiPath;
  
      fetch(newUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.data) {
            if (Array.isArray(data.data)) {
              resolve(data.data); 
            } else {
              
              reject(new Error('Invalid response data'));
            }
          } else {
            
            resolve([]); // Resolve with an empty array if data.data is not available
          }
        })
        .catch((error) => {
          console.log('Error fetching data:', error);
          reject(error);
        });
      });
  }