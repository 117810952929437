// validate email *******************************************************************************************************
export function validateEmail(mail) {

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
      return false;
};

// validate text entered for purpose of email *******************************************************************************************************
export function validateTextEmail(email, subdomain) {
    var letterNumber = /^[0-9a-zA-Z]+$/;
    var usethis = "";

    // Check if there is an @ symbol in txt
    var atcheck = email.includes("@");
    if (atcheck === true ) {
      // If it contains @ remove everything after the @
      usethis = email.split('@')[0];
    } else {
      usethis = email;
    }

    var final = "";
    // Check if result from above is usable (contains 0-9 or a-z only)
    if(usethis.match(letterNumber)) {
      final = String(usethis) + String(subdomain);
      return [true,final];
    } else {
      return false;
    }
}  

  // validate text entered is clean. Only 0-9 or a-z or A-Z ***************************************************************************************
  export function validateClean(txt) {
    var letterNumber = /^[0-9a-zA-Z]+$/;

    if(txt.match(letterNumber)) {
        return true;
    } else {
        return false;
    }
};

// remove # sign ***************************************************************************************
export function removeHashtag(txt) {
    var newTxt = txt.replace(/#/g, '')
  return newTxt;
}